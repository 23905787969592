.backgroundImage {
    background-size: contain;
    background-repeat: no-repeat;
    width: 90%;
    left: 5%;
    position: relative;
    opacity: 65%;
}

.form {
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 60%;
}

.form {
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 25%;
}

.formBackground {
    background-color: rgba(201, 201, 201, 0.63);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    text-shadow: 1px 1px 2px rgb(61, 61, 61), 0 0 8px rgb(238, 204, 14);
}

.list {
    text-align: start;
}